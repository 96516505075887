export default [
  {
    name: "组件库",
    children: [
      {
        name: "ElementUI",
        url: "https://element.eleme.cn/#/zh-CN/component/form",
        iconFileUrl: "element_ui.png",
      },
      {
        name: "Element-plus",
        url: "https://element-plus.org/zh-CN/",
        iconFileUrl: "element_ui.png",
      },
      {
        name: "vxe-table",
        url: "https://vxetable.cn/#/table/start/install",
        iconFileUrl: "vxeTable.png",
      },
      {
        name: "uView",
        url: "https://uviewui.com/components/intro.html",
        // iconFileUrl: 'uView.png'
      },
      {
        name: "v-charts",
        url: "https://v-charts.js.org/#/",
        iconFileUrl: "vcharts.png",
      },
      {
        name: "e-charts",
        url: "https://echarts.apache.org/examples/zh/index.html",
        iconFileUrl: "echarts.png",
      },
      {
        name: "datav",
        url: "http://datav.jiaminghi.com/guide/",
        iconFileUrl: "dataV.png",
      },
      {
        name: "react native ant",
        url: "https://rn.mobile.ant.design/index-cn",
        pinyin: "reactnativeant",
      },
      {
        name: "vuetify",
        url: "https://vuetifyjs.com",
      },
      // {
      //   name: "formily",
      //   url: "https://vue.formilyjs.org/",
      // },
    ],
  },
  {
    name: "框架",
    children: [
      {
        name: "Vue.js",
        url: "https://cn.vuejs.org/",
        iconFileUrl: "Vue.png",
      },
      {
        name: "uni-app",
        url: "https://uniapp.dcloud.io/component/button",
        iconFileUrl: "uniApp.png",
      },
      {
        name: "react native",
        url: "https://www.reactnative.cn/",
        pinyin: "reactnative",
      },
      // {
      //   name: "ES6",
      //   url: "https://es6.ruanyifeng.com/#docs/promise",
      //   iconFileUrl: "ECMAScript2015.png",
      // },
      {
        name: "XE-utils",
        url: "https://x-extends.gitee.io/xe-utils/#/",
        iconFileUrl: "vxeTable.png",
      },
      // {
      //   name: "oasis-engine",
      //   url: "https://oasis-engine.gitee.io/",
      //   iconFileUrl: "oasis.png",
      // },
      // {
      //   name: "Threejs",
      //   url: "http://www.yanhuangxueyuan.com/threejs/docs/index.html",
      //   iconFileUrl: "threejs.png",
      // },
      // {
      //   name: "UEditor",
      //   url: "http://fex.baidu.com/ueditor/#start-config",
      //   iconFileUrl: "UEditor.png",
      // },
      // {
      //   name: "yao",
      //   url: "https://yaoapps.com/",
      //   iconFileUrl: "yao.png",
      // },
    ],
  },
  {
    name: "UI图层",
    children: [
      {
        name: "unDraw",
        url: "https://undraw.co/illustrations",
      },
      {
        name: "原型图设计",
        url: "https://modao.cc/team/panel/te15r1vdefault",
        pinyin: "yxtsj",
      },
      {
        name: "visily原型图",
        url: "https://www.visily.ai/",
        pinyin: "visilyyxt",
      },
    ],
  },
  {
    name: "人工智能",
    children: [
      {
        name: "人工智能导航站",
        url: "https://www.ainav.cn/",
        pinyin: "rgzndhz",
      },
      {
        name: "chatGPT - 官方",
        url: "https://chat.openai.com/chat",
        pinyin: "chatgptgf",
        iconFileUrl: "chatGPT.png",
      },
      {
        name: "Google - bard",
        url: "https://bard.google.com/?hl=zh",
        pinyin: "googlebard",
      },
      {
        name: "phind",
        url: "https://www.phind.com/",
      },
      {
        name: "warp",
        url: "https://www.warp.dev",
      },
      {
        name: "lumaNeRf",
        url: "https://lumalabs.ai",
      },
      // {
      //   name: "bai",
      //   url: "https://chatbot.theb.ai/",
      //   pinyin: "bai",
      // },
      // {
      //   name: "openAI - console",
      //   url: "https://platform.openai.com/overview",
      //   pinyin: "openaiconsole",
      //   iconFileUrl: "openAI.png",
      // },
      {
        name: "飞桨Git",
        url: "https://gitee.com/paddlepaddle/PaddleOCR",
        pinyin: "fjgit",
        iconFileUrl: "feijiang.png",
      },
      {
        name: "飞桨Gitee",
        url: "https://gitee.com/paddlepaddle",
        pinyin: "fjgitee",
        iconFileUrl: "feijiang.png",
      },
      {
        name: "飞桨AI Studio",
        url: "https://aistudio.baidu.com/aistudio/index",
        pinyin: "fjaistudio",
        iconFileUrl: "feijiang.png",
      },
      {
        name: "飞桨BML",
        url: "https://ai.baidu.com/bml/",
        pinyin: "fjbml",
        iconFileUrl: "feijiang.png",
      },
      {
        name: "飞桨EasyDL",
        url: "https://ai.baidu.com/easydl/app/objdct/overview",
        pinyin: "fjeasydl",
      },
      // {
      //   name: "GPT4 online",
      //   url: "https://stablediffusion.fr/chatgpt4",
      //   pinyin: "GPT4online",
      // },
      // {
      //   name: "ORA AI",
      //   url: "https://ora.ai/dashboard/chatbots",
      //   pinyin: "orabot",
      // },
      {
        name: "roboflow",
        url: "https://app.roboflow.com/",
      },
    ],
  },
  {
    name: "公司服务器站点",
    children: [
      {
        name: "常用网址",
        url: "http://192.168.103.29:8080/",
        pinyin: "cywz",
      },
      {
        name: "协作项目",
        url: "https://oa.fzgdyk.com",
        pinyin: "xzxm",
      },
      {
        name: "运维系统",
        url: "https://feedback.fzgdyk.com",
        pinyin: "ywxt",
      },
      {
        name: "公司GIT",
        url: "http://192.168.103.31:3000",
        pinyin: "gsgit",
      },
      {
        name: "铁塔完整版任务分解",
        url: "https://j6lt7d5c83.feishu.cn/base/bascnYJHZQQzSzAD42Xo8ya6QQh?table=tblGkTNMOSXrFSEp&view=vew0Tu0VCB",
        pinyin: "ttwzbrwfj",
      },
      {
        name: "加强版手机端权限共享文档",
        url: "https://www.kdocs.cn/p/127995462072",
        pinyin: "jqbsjdqxgxwd",
      },
      {
        name: "禅道",
        url: "http://192.168.103.14:12345/zentao/my/",
        pinyin: "cd",
        iconFileUrl: "chandao.png",
      },
      {
        name: "公司wiki",
        url: "http://192.168.103.3:3000",
        pinyin: "gswiki",
      },
    ],
  },
  {
    name: "云服务器",
    children: [
      {
        name: "gitee",
        url: "https://gitee.com/",
        iconFileUrl: "gitee.png",
      },
      {
        name: "阿里云",
        url: "https://www.aliyun.com/",
        pinyin: "aly",
        iconFileUrl: "aly.png",
      },
      {
        name: "腾讯云",
        url: "https://cloud.tencent.com/",
        pinyin: "txy",
        iconFileUrl: "txy.png",
      },
      {
        name: "映画の图床",
        url: "https://imgs.top/user/tokens",
        pinyin: "yxtc",
      },
      {
        name: "纷享云",
        url: "https://bitiful.dogecast.com/buckets/auto-reply/upload",
        pinyin: "yxtc",
      },
    ],
  },
  {
    name: "其他",
    children: [
      {
        name: "关键字绘画",
        url: "https://discord.com/channels/662267976984297473/997260995883966464",
        pinyin: "gjzhh",
      },
      {
        name: "待办",
        url: "https://todo.fxysy.top",
        pinyin: "db",
        iconFileUrl: "daiban.png",
      },
      {
        name: "飞速云",
        url: "https://feisucloud.net/user/node",
        pinyin: "fsy",
        iconFileUrl: "feisuyun.png",
      },
      {
        name: "极速MAX",
        url: "https://pro.jisumax.cc",
        pinyin: "jsmax",
      },
      {
        name: "SSRDOG",
        url: "https://dog.ssrdog111.com/#/account/store",
        pinyin: "ssrdog",
      },
      {
        name: "老猫云",
        url: "https://lm.laomaoyun.me",
        pinyin: "lmy",
      },
      {
        name: "Catnet",
        url: "https://www.58catnet.com/#/dashboard",
        pinyin: "catnet",
      },
      {
        name: "樱花动漫",
        url: "https://m.yhdmp.live",
        pinyin: "yhdm",
        hide: true,
      },
      {
        name: "动漫高清资源",
        url: "https://share.acgnx.se/",
        pinyin: "dmgqzy",
        hide: true,
      },
      {
        name: "动漫壁纸",
        url: "http://iw233.cn/api/Random.php",
        type: "newWindow",
        pinyin: "dmbz",
        hide: true,
      },
      {
        name: "打印报表sql代码生成",
        url: "https://lf.fxysy.top:20004",
        pinyin: "dybbsqldmsc",
      },
      {
        name: "快科技",
        url: "https://www.mydrivers.com/",
        pinyin: "kkj",
        iconFileUrl: "kkj.png",
      },
      {
        name: "express项目",
        url: "http://escook.cn:8088",
        pinyin: "expressxm",
      },
      {
        name: "星座运势",
        url: "http://astro.sina.com.cn/fate_day_leo/",
        pinyin: "xzys",
        iconFileUrl: "xzys.png",
      },
      {
        name: "星露谷物语维基",
        url: "https://zh.stardewvalleywiki.com/Stardew_Valley_Wiki",
        pinyin: "xlgwywj",
        iconFileUrl: "xlgwy.png",
      },
      {
        name: "星露谷物语MOD",
        url: "https://www.nexusmods.com/stardewvalley",
        pinyin: "xlgwymod",
        iconFileUrl: "xlgwy.png",
      },
      {
        name: "超级高清壁纸",
        url: "https://wallpaperhub.app/wallpapers",
        pinyin: "cjgqbz",
      },
      {
        name: "PDF小工具",
        url: "https://tools.pdf24.org/zh/",
        pinyin: "pdfxgj",
      },
      {
        name: "动漫P站",
        url: "https://pixivic.com/?VNK=949e7bdb",
        pinyin: "dmpz",
        hide: true,
      },
      {
        name: "免费API",
        url: "https://www.mxnzp.com/doc/list",
        pinyin: "mfapi",
      },
      {
        name: "腾讯帮小忙",
        url: "https://tool.browser.qq.com/category/img",
        pinyin: "txbxm",
      },
    ],
  },
  {
    name: "轻小说文库",
    children: [
      {
        name: "实教",
        url: "https://www.wenku8.net/novel/1/1973/index.htm",
        pinyin: "sj",
      },
    ],
  },
  {
    name: "摄影小子",
    children: [
      {
        name: "光污染查看",
        url: "https://www.darkmap.cn",
        pinyin: "gwrck",
      },
      {
        name: "新知卫星地图",
        url: "https://www.xinzhi.space/#/app",
        pinyin: "xzwxdt",
      },
      {
        name: "天气查询",
        url: "https://www.windy.com",
        pinyin: "tqcx",
      },
    ],
  },
]
