
// 每个item 的接口
interface WebUrlItem {
  name: string;
  url: string;
  iconFileUrl?: string;
  type?: string;
  pinyin?: string;
  hide?: boolean;
}

// 每个item group 的接口
interface WebUrlGroup {
  name: string;
  children: WebUrlItem[];
}

// webUrl数据接口
interface DataProps {
  objArray: WebUrlGroup[];
  cacheArr: WebUrlGroup[];
  searchVal: string;
  tokenCheckSuccess: boolean;
  clientWidth: number;
  titleClickList: string[];
}
import { defineComponent, reactive, toRefs, watch, onBeforeMount } from "vue";
import webUrl from "../parameter/webUrl";
import { ElMessageBox, ElMessage } from "element-plus";

export default defineComponent({
  name: "HelloWorld",
  setup() {
    // 定义data
    const data: DataProps = reactive({
      objArray: [],
      cacheArr: [
        {
          name: "搜索结果",
          children: [],
        },
      ],
      searchVal: "",
      // 令牌检查结果
      tokenCheckSuccess: false,
      // 页面宽度
      clientWidth: document.documentElement.clientWidth,
      // 点击次数
      titleClickList: [],
    });

    // 定义方法
    const methods = reactive({
      /**
       * 获取隐藏页面
       */
      getMoreWeb: (items: WebUrlGroup) => {
        if (
          data.titleClickList.filter((item) => item === items.name).length === 4
        ) {
          for (let item of data.objArray) {
            if (item.name === items.name) {
              for (let child of item.children) {
                if (child.hide) child.hide = false;
              }
              break;
            }
          }
        } else {
          data.titleClickList.push(items.name);
        }
      },

      /**
       * @description: 打开链接方法
       * @param url url路径
       * @return void
       */
      openUrl: (item: WebUrlItem) => {
        // undefined可以判断，不会报错
        if (item.type === "newWindow") {
          window.open(item.url);
        } else {
          window.open(item.url, "_self");
        }
      },

      /**
       * @description: 搜索时过滤数据
       * @param value 搜索值
       * @return void
       */
      changSearchVal: (value: string) => {
        data.cacheArr[0]["children"] = [];
        const re = new RegExp(".*?" + value + ".*", "i");
        for (let items of data.objArray) {
          for (let item of items["children"]) {
            if (re.test(item.name) || (item.pinyin && re.test(item.pinyin))) {
              data.cacheArr[0]["children"].push(item);
            }
          }
        }
      },

      /**
       * @description: 获取图片流
       * @param fileName 文件名
       * @return 文件流
       */
      getImg: (fileName: string) => {
        return require("../assets/png/" + fileName);
      },

      /**
       * @description: 输入令牌
       * @return void
       */
      checkToken: () => {
        ElMessageBox.prompt("请输入令牌", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          inputPattern: /(?=^.{1,}$).*$/,
          inputErrorMessage: "请输入令牌",
        }).then(({ value }) => {
          methods.getCheckResult(window.btoa(value), "manual");
        });
      },

      /**
       * @description: 请求接口验证令牌
       * @param token 输入的令牌
       * @param getType 验证的方式
       * @return void
       */
      getCheckResult: async (token: string, getType: string) => {
        if (token === "TGlubjUzNTY=") {
          data.objArray = webUrl;
          data.tokenCheckSuccess = true;
          localStorage.setItem("naviagtionToken", token);
          // 如果不是自动调用的时候就提示信息
          if (getType !== "auto") {
            ElMessage({
              message: "验证成功!",
              type: "success",
              duration: 1000,
            });
          }
        } else {
          data.objArray = [];
          data.tokenCheckSuccess = false;
          // 如果不是自动调用的时候就提示信息
          if (getType !== "auto") {
            ElMessage({ message: "验证失败!", type: "error", duration: 1000 });
          }
        }
      },
    });

    // 生命周期 ==> beforeMount
    onBeforeMount(() => {
      // 监听浏览器大小
      window.onresize = function () {
        data.clientWidth = document.documentElement.clientWidth;
      };

      let str = localStorage.getItem("naviagtionToken");
      str && str !== "" && methods.getCheckResult(str, "auto");
    });

    // 定义监听
    watch(
      () => data.searchVal,
      (newVal: string) => {
        methods.changSearchVal(newVal);
      }
    );

    const refData = toRefs(data); // 解构data，使在dom中可以正常使用data的响应式data
    const refMethods = toRefs(methods); // 解构methods

    return {
      ...refData,
      ...refMethods,
    };
  },
});
