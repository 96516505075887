import { createApp } from 'vue'
import App from './App.vue'
import 'element-plus/dist/index.css'

// 全量引入
// import ElementPlus from 'element-plus'
// app.use(ElementPlus)

// 按需引入
import { ElConfigProvider, ElButton, ElRow, ElCol, ElInput, ElRate, ElDrawer, ElMessageBox, ElMessage } from 'element-plus';
import { Edit } from '@element-plus/icons-vue'  // 按需引入 Icon 图标

// 引入组件要使用的语言（示例是中文）
// import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App)

// 全局注册
app.component('ElConfigProvider', ElConfigProvider); // 用于配置中文
app.component(ElButton.name, ElButton);
app.component(ElRow.name, ElRow);
app.component(ElCol.name, ElCol);
app.component(ElInput.name, ElInput);
app.component(ElRate.name, ElRate);
app.component(ElDrawer.name, ElDrawer);
app.component(Edit.name, Edit) // 注册 Icon 图标

// 全局属性 ==> 即vue2中的vue.property()
app.config.globalProperties.$messageBox = ElMessageBox
app.config.globalProperties.$message = ElMessage
// app.provide('$messageBox', ElMessageBox)
// app.provide('$message', ElMessage)

app.mount('#app')
