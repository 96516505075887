
// 使用中文
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { defineComponent } from "vue";
import Main from "./components/Main.vue";

export default defineComponent({
  name: "App",
  components: {
    Main,
  },
  data() {
    return {
      //在此引入locale属性设置为中文
      locale: zhCn,
    };
  },
});
